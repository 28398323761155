import '@usb-shield/react-grid/dist/library/styles/index.css'

import Link from 'next/link'
import USBButton from '@usb-shield/react-button'
import USBLink from '@usb-shield/react-link'
import styles from '../desktopNavBar.module.scss'

export const ProfileDropdownMenu = ({ hideOverlayAndMenu, onLogoutClick}: {
  hideOverlayAndMenu: any,
  onLogoutClick: any
}) => {
  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
 
  return (
    <div>
      {(process.env.SITE_BRAND !== 'elavon') && (process.env.FEATURE_SANDBOX_PROJECTS !== "false") &&
        <USBLink>
          <Link href='/credentials'>
            <a onClick={hideOverlayAndMenu}>Credentials</a>
          </Link>
        </USBLink>
      }

      {(process.env.SITE_BRAND !== 'elavon') && (process.env.FEATURE_SUPPORT_TICKET !== 'false') &&
        <USBLink>
          <Link href='/support'>
            <a onClick={hideOverlayAndMenu}>
              Support Tickets
            </a>
          </Link>
        </USBLink>
      }

      {isElavon && (
        <USBLink>
          <Link
            href={
              process.env.FEATURE_DEVELOPER_DASHBOARD !== 'false'
                ? '/dashboard'
                : '/support'
            }
          >
            Dashboard
          </Link>
        </USBLink>
      )}

      <USBLink>
        <Link href='/profile'>
          <a onClick={hideOverlayAndMenu}>{!isElavon ? 'Account Profile' : 'Account'}</a>
        </Link>
      </USBLink>
      <USBButton
        ctaStyle="standard"
        emphasis="heavy"
        size='medium'
        handleClick={onLogoutClick}
        id='logoff-link'
        addClasses={styles.logOffLink}
        dataTestId='onLogoutClick'
      >
        <span className={isElavon ? styles.redLogout : ''}>Log Out</span>
      </USBButton>
    </div>
  )
}
