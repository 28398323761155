import '@usb-shield/react-grid/dist/library/styles/index.css'
import { createRef, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { signOut, useSession } from 'next-auth/react'

import USBButton from '@usb-shield/react-button'
import { USBColumn } from '@usb-shield/react-grid'
import USBLink from '@usb-shield/react-link'
import pageHeaderStyles from './pageHeader.module.scss'
import styles from './mobileNavBar.module.scss'
import { useRouter } from 'next/router'
import { downloadDocument } from '@/modules/products/ProductOverviewPage/services/downloadDocument.service'
import { showSuccessToast } from '@/utils/toast/toast'
import { PageHeaderMenuItem } from './pageHeader.model'
import Link from 'next/link'
import { useCookies } from 'react-cookie'
import RegionPicker from '@/components/RegionPicker/RegionPicker'
import { InputSearch } from '@/components/InputSearch/InputSearch'
import { useSearchBarUtil } from './util/SearchBarUtil'
import { useQueryClient } from '@tanstack/react-query'
import { convertStringIntoCamelCase } from '@/utils/strings/strings'
import { fetchAccessibleProductLink } from '@/utils/products/global-element-product-access-check'
import { USBIconClose } from '@usb-shield/react-icons'
import ChallengeWidgetModal from '@/modules/challenge-widget/ChallengeWidget'
import { getLastStepupDuration } from '@/utils/auth-widget/auth-widget'

const mobileHeaderNavColumn = {
  span: null,
  spans: {
    xlarge: 16,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'block',
  padding: 'zero',
  align: 'start',
  justify: 'stretch',
}

export const MobileNavBar = ({
  menuItems,
  imageURL,
  mainmenuId,
  regionOptions,
  isLoginSignUpDisabled,
  isSearchDisabled,
  allProductVersionsInfo,
  authWidgetConfigData,
}: any) => {
  const { handleSearchKeyDown } = useSearchBarUtil()
  const { data: session, status } = useSession()
  const queryClient = useQueryClient()
  const menuListWrapperRef = useRef(null)
  const levelOneMenuListWrapperRef = useRef(null)
  const levelTwoMenuListWrapperRef = useRef(null)
  const levelThreeMenuListWrapperRef = useRef(null)
  const menuWrapperRef = useRef(null)
  const backOneLevelRef = useRef(null)
  const [cookies] = useCookies(['locale', 'countryCode'])
  const countryCode = (cookies.countryCode || 'US').toLowerCase()

  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
  const sandboxProductsExists: boolean = process.env.FEATURE_SANDBOX_PROJECTS === 'true'

   // MFA Challenge Widget
   const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
   const [challengeModalIsOpen, setChallengeModalIsOpen] = useState<boolean>(false)
   const [activityName, setActivityName] = useState<string>('')
   const [callBackUrl, setCallBackUrl] = useState<string>('')
   useEffect(() => {}, [challengeModalIsOpen, callBackUrl])
   // End MFA Challenge Widget

  const inSessionMobileMenu = !isElavon ? [
    {
      id: 'credentials-mobile',
      value: 'Credentials',
      url: '/credentials',
    },
    {
      id: 'profile-mobile',
      value: 'Profile',
      url: '/profile',
    },
    {
      id: 'support-mobile',
      value: 'Support Tickets',
      url: '/support',
    },
  ] : [
    {
      id: 'dashboard-mobile',
      value: 'Dashboard',
      url: '/dashboard',
    },
    {
      id: 'account-mobile',
      value: 'Account',
      url: '/profile',
    }
  ]

  if (process.env.FEATURE_SUPPORT_TICKET !== 'true') {
    const SUPPORT_ITEM_INDEX = inSessionMobileMenu.findIndex((item: any) => {
      return item.id.toLowerCase() === 'support-mobile'
    })
    
    if (SUPPORT_ITEM_INDEX !== -1) {
      inSessionMobileMenu.splice(SUPPORT_ITEM_INDEX, 1)
    }
  }
  
  if (process.env.FEATURE_DEVELOPER_DASHBOARD !== 'true') {
    const CREDENTIALS_ITEM_INDEX = inSessionMobileMenu.findIndex((item: any) => {
      return item.id.toLowerCase() === 'credentials-mobile'
    })
    
    if (CREDENTIALS_ITEM_INDEX !== -1) {
      inSessionMobileMenu.splice(CREDENTIALS_ITEM_INDEX, 1)
    }
  }

  const router = useRouter()

  const onLogoutClick = async () => {
    await signOut({ callbackUrl: '/', redirect: false })
    showSuccessToast('Logout successful')
    router.push('/').then()
    queryClient.removeQueries()
  }

  const onLoginClick = () => {
    router.push('/login').then()
  }

  let mainMenuRef: any = ''

  mainMenuRef = useRef(mainmenuId?.mainMenuId?.map(() => createRef())) as any

  const hamburgerKeyup = (e: KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()

    // toggle the menu if the enter key is pressed
    if (e.key === 'Enter') {
      toggleMenuCallback()
    }
  }

  const toggleMenuCallback = () => {
    if (typeof window !== 'undefined') {
      const menuWrapper = menuWrapperRef.current as any
      const levelOneMenuListWrapper = levelOneMenuListWrapperRef.current as any
      const levelTwoMenuListWrapper = levelTwoMenuListWrapperRef.current as any
      const levelThreeMenuListWrapper =
        levelThreeMenuListWrapperRef.current as any
      const isVisibleClass = 'isVisible'
      const isActiveClass = 'is-active'
      // Toggle menu wrapper
      menuWrapper.classList.toggle(isVisibleClass)
      menuWrapper.style.display = 'block'
      if (!menuWrapper.classList.contains(isVisibleClass)) {
        levelOneMenuListWrapper.style.display = 'none'
        levelTwoMenuListWrapper.style.display = 'none'
        levelThreeMenuListWrapper.style.display = 'none'
        menuWrapper.style.display = 'none'
        const menuLinks = menuWrapper.querySelectorAll('a')
        for (const menuLink of menuLinks) {
          menuLink.classList.remove(isActiveClass)
        }
        // Enable hamburger icon
        const hamburgerIcon = document.getElementById('sm_menu_ham') as any
        hamburgerIcon.style.display = 'block'
        // Disable hamburger close icon
        const hamburgerCloseIcon = document.getElementById(
          'hamburgerCloseIcon'
        ) as any
        hamburgerCloseIcon.style.display = 'none'
        // Disable the overlay
        const mainMenu = document.getElementById(`showOverlay`) as any
        mainMenu.style.display = 'none'
        mainMenu.classList.remove(pageHeaderStyles.darkMode)
      } else {
        // Disable hamburger icon
        const hamburgerIcon = document.getElementById('sm_menu_ham') as any
        hamburgerIcon.style.display = 'none'
        // Enable hamburger close icon
        const hamburgerCloseIcon = document.getElementById(
          'hamburgerCloseIcon'
        ) as any
        hamburgerCloseIcon.style.display = 'block'
        hamburgerCloseIcon.focus()
        // Show overlay with dark mode
        const mainMenu = document.getElementById(`showOverlay`) as any
        mainMenu.style.display = 'block'
        mainMenu.classList.add(pageHeaderStyles.darkMode)
      }
    }
  }

  let hamburgerCloseIcon: HTMLElement
  if (typeof window !== 'undefined') {
    hamburgerCloseIcon = document.getElementById('hamburgerCloseIcon') as HTMLElement
  }

  const setHamburgerCloseIconFocus = () => {
    hamburgerCloseIcon?.focus()
  }

  const setTimerOnHamburgerCloseIconFocus = () => {
    setTimeout(() => {
      setHamburgerCloseIconFocus()
    }, 1000);
  }

  const toggleLevel1Callback = (e: any, id: string, levelRef: any) => {
    // Remove parent menu if no products available
    const getParentMenu: any = document.querySelectorAll<HTMLElement>(`.level3`)
    for(const element of getParentMenu) {
      if(element.innerHTML) {
        // empty condition      
      } else {
        element.closest('li').remove();
      }
    }
    if (typeof window !== 'undefined') {
      // browser code
      const isActiveClass = 'is-active'
      const levelOneMenuListWrapper = levelOneMenuListWrapperRef.current as any
      const levelOneLinks = levelRef
      // Toggle submenu wrapper
      const subMenuWrapper =
        levelOneMenuListWrapper.querySelector('.subMenuWrapper')
      const siblingList = levelOneLinks?.current?.nextElementSibling
      if (siblingList) {
        e.preventDefault()
        siblingList.classList.add(isActiveClass)
        const cloneSiblingList = siblingList.cloneNode(true)
        subMenuWrapper.innerHTML = ''
        subMenuWrapper.append(cloneSiblingList)
        levelOneMenuListWrapper.style.transform = 'none'
        levelOneMenuListWrapper.style.display = 'block'
        // Level 1 - Active Menu
        const levelOneText = document.getElementById(
          'activeLevelOneMenu'
        ) as any
        levelOneText.innerHTML = document.getElementById(id)?.innerText
        // Level 2 - Active Menu
        const levelTwoText = document.getElementById('level2-backMenu') as any
        levelTwoText.innerHTML = document.getElementById(id)?.innerText

        setTimerOnHamburgerCloseIconFocus()
      }
    }
  }

  //Back button callback - Level 1 menu
  const togglebackOneLevelCallback = () => {
    if (typeof window !== 'undefined') {
      const isActiveClass = 'is-active'
      const parent = levelOneMenuListWrapperRef.current as any
      parent.style = ''
      parent.previousElementSibling
        .querySelector('.is-active')
        .classList.remove(isActiveClass)

      setTimerOnHamburgerCloseIconFocus()
    }
  }

  //Back button callback - Level 2 menu
  const togglebackSecondLevelCallback = () => {
    if (typeof window !== 'undefined') {
      const isActiveClass = 'is-active'
      const parent = levelTwoMenuListWrapperRef.current as any
      parent.style = ''
      parent.previousElementSibling
        .querySelector('.is-active')
        .classList.remove(isActiveClass)

      setTimerOnHamburgerCloseIconFocus()
    }
  }

  //Back button callback - Level 3 menu
  const togglebackThirdLevelCallback = () => {
    if (typeof window !== 'undefined') {
      const isActiveClass = 'is-active'
      const parent = levelThreeMenuListWrapperRef.current as any
      parent.style = ''
      parent.previousElementSibling
        .querySelector('.is-active')
        ?.classList.remove(isActiveClass)
      parent.previousElementSibling
        .querySelector(`ul.${styles.documentPages}`)
        ?.classList.remove(`${styles.documentPages}`)

      setTimerOnHamburgerCloseIconFocus()
    }
  }

  useEffect(() => {
    //called when level 2 menu has any sub-menus
    const toggleLevel2Callback = (e: any) => {
      if (typeof window !== 'undefined') {
        const isActiveClass = 'is-active'
        const levelTwoMenuListWrapper =
          levelTwoMenuListWrapperRef.current as any
        const levelThreeMenuListWrapper =
          levelTwoMenuListWrapper.querySelector('.subMenuWrapper')
        const target = e.target
        if (
          target.tagName.toLowerCase() === 'a' &&
          target?.nextElementSibling?.innerHTML
        ) {
          const siblingList = target.nextElementSibling
          e.preventDefault()
          target.classList.add(isActiveClass)
          const cloneSiblingList = siblingList.cloneNode(true)
          levelThreeMenuListWrapper.innerHTML = ''
          levelThreeMenuListWrapper.append(cloneSiblingList)
          levelTwoMenuListWrapper.style.transform = 'none'
          levelTwoMenuListWrapper.style.display = 'block'
          const levelOneText = document.getElementById(
            'activeLevelTwoMenu'
          ) as any
          levelOneText.innerHTML = e.target.innerText

          // Remove productSuite link if no products available
          const getProductSuiteList: any = document.querySelectorAll<HTMLElement>(`.emptyProductsForProductSuite`)
          for (const element of getProductSuiteList) {
              element.closest('li').remove()
          }

          setTimerOnHamburgerCloseIconFocus()
        }
      }
    }

    //called when level 3 menu has any sub-menus
    const toggleLevel3Callback = (e: any) => {
      // Render MFA Challenge Widget
      if (featureMFA && e.target.innerText == 'Usage' && authWidgetConfigData) {
        if (session && getLastStepupDuration(authWidgetConfigData.MFA_WIDGET_CHALLENGE_INTERVAL_SECONDS)) {
          e.preventDefault()
          setCallBackUrl(e.target.href)
          setActivityName('Usage')
          setChallengeModalIsOpen(true)
        }
      }
      // End Render MFA Challenge Widget
      if (typeof window !== 'undefined') {
        const isActiveClass = 'is-active'
        const levelThreeMenuListWrapper =
          levelThreeMenuListWrapperRef.current as any
        const levelFourMenuListWrapper =
          levelThreeMenuListWrapper.querySelector('.subMenuWrapper')
        const target = e.target
        if (
          target.tagName.toLowerCase() === 'a' &&
          target?.nextElementSibling?.innerHTML
        ) {
          const siblingList = target.nextElementSibling
          siblingList.classList.add(`${styles.documentPages}`)
          e.preventDefault()
          target.classList.add(isActiveClass)
          const cloneSiblingList = siblingList.cloneNode(true)
          levelFourMenuListWrapper.innerHTML = ''
          levelFourMenuListWrapper.append(cloneSiblingList)
          levelThreeMenuListWrapper.style.transform = 'none'
          levelThreeMenuListWrapper.style.display = 'block'
          const levelTwoText = document.getElementById(
            'activeLevelThreeMenu'
          ) as any
          levelTwoText.innerHTML = e.target.innerText
          // Level 3 - Active Menu
          const levelOneText = document.getElementById(
            'activeLevelTwoMenu'
          ) as any
          const levelThreeText = document.getElementById(
            'level3-backMenu'
          ) as any
          levelThreeText.innerHTML = levelOneText.innerText

          setTimerOnHamburgerCloseIconFocus()
        }
      }
    }

    const escapeKeyPressed = (event: any) => {
      if (event.key === 'Escape') {
        const menuWrapper = menuWrapperRef.current as any
        if (menuWrapper.classList.contains('isVisible')) {
          toggleMenuCallback()
        }
      }
    }

    const enterKeyPressed = (event: any) => {
      if (event.key === 'Enter') {
        switch (event.target.id) {
          case 'levelOne':
            togglebackOneLevelCallback()
            break;
          case 'levelTwo':
            togglebackSecondLevelCallback()
            break;
          case 'levelThree':
            togglebackThirdLevelCallback()
            break;
          default:
            break;
        }
      }
    }

    const lastItemFocusOut = (event: any) => {
      setHamburgerCloseIconFocus()
    }

    let levelZeroLastItem: HTMLElement
    let levelOne: HTMLElement
    let levelOneLastItem: HTMLElement
    let levelTwo: HTMLElement
    let levelTwoLastItem: HTMLElement
    let levelThree: HTMLElement
    let levelThreeLastItem: HTMLElement

    const hamburgerCloseIconFocusOut = (event: any) => {
      let level0, level1, level2, level3 = false;

      const menuWrapper = menuWrapperRef.current as any
      level0 = menuWrapper.classList.contains('isVisible')
      const levelZero = document.querySelector("#menu-level1")
      levelZeroLastItem = levelZero?.lastChild?.firstChild as HTMLElement

      const levelOneMenuListWrapper = levelOneMenuListWrapperRef.current as any
      level1 = levelOneMenuListWrapper.style.display === 'block'
      levelOne = document.getElementById('levelOne') as HTMLElement
      levelOneLastItem = levelOne?.nextElementSibling?.nextElementSibling?.firstChild?.lastChild as HTMLElement

      const levelTwoMenuListWrapper = levelTwoMenuListWrapperRef.current as any
      level2 = levelTwoMenuListWrapper.style.display=== 'block'
      levelTwo = document.getElementById('levelTwo') as HTMLElement
      levelTwoLastItem = levelTwo?.nextElementSibling?.nextElementSibling?.firstChild?.lastChild as HTMLElement

      const levelThreeMenuListWrapper = levelThreeMenuListWrapperRef.current as any
      level3 = levelThreeMenuListWrapper.style.display === 'block'
      levelThree = document.getElementById('levelThree') as HTMLElement
      levelThreeLastItem = levelThree?.nextElementSibling?.nextElementSibling?.firstChild?.lastChild as HTMLElement

      if (level3) {
        levelThree?.setAttribute("tabindex", "0")
        levelThree?.focus()
        levelThree?.addEventListener('keydown', enterKeyPressed, false)
        levelThreeLastItem.addEventListener('focusout', lastItemFocusOut, false)
        return
      }

      if (level2) {
        levelTwo?.setAttribute("tabindex", "0")
        levelTwo?.focus()
        levelTwo?.addEventListener('keydown', enterKeyPressed, false)
        levelTwoLastItem.addEventListener('focusout', lastItemFocusOut, false)
        return
      }

      if (level1) {
        levelOne?.setAttribute("tabindex", "0")
        levelOne?.focus()
        levelOne?.addEventListener('keydown', enterKeyPressed, false)
        levelOneLastItem.addEventListener('focusout', lastItemFocusOut, false)
        return
      }

      if (level0) {
        if (process.env.SITE_BRAND === 'elavon') {
          let inputSearch = document.getElementById('edp_search_input_mobile')
          inputSearch?.focus()
        } else {
          const levelZeroFirstItem = levelZero?.firstChild?.firstChild as HTMLElement
          levelZeroFirstItem?.focus()
        }

        levelZeroLastItem.addEventListener('focusout', lastItemFocusOut, false)
      }
    }

    const element1 = levelOneMenuListWrapperRef.current as any
    element1.addEventListener('click', toggleLevel2Callback)

    const element2 = levelTwoMenuListWrapperRef.current as any
    element2.addEventListener('click', toggleLevel3Callback)

    document.addEventListener('keydown', escapeKeyPressed, false)

    hamburgerCloseIcon?.addEventListener('focusout', hamburgerCloseIconFocusOut, false)

    return () => {
      element1.removeEventListener('click', toggleLevel2Callback)
      element2.removeEventListener('click', toggleLevel3Callback)

      document.removeEventListener('keydown', escapeKeyPressed, false)

      hamburgerCloseIcon?.removeEventListener('focusout', hamburgerCloseIconFocusOut, false)

      levelZeroLastItem?.removeEventListener('focusout', lastItemFocusOut, false)

      levelOne?.removeEventListener('keydown', enterKeyPressed, false)
      levelOneLastItem?.removeEventListener('focusout', lastItemFocusOut, false)

      levelTwo?.removeEventListener('keydown', enterKeyPressed, false)
      levelTwoLastItem?.removeEventListener('focusout', lastItemFocusOut, false)

      levelThree?.removeEventListener('keydown', enterKeyPressed, false)
      levelThreeLastItem?.removeEventListener('focusout', lastItemFocusOut, false)
    }
  }, [session])

  let renderMobileMenuItems
  if (menuItems) {
    renderMobileMenuItems = menuItems?.map(function (
      firstLevelMenuItem: PageHeaderMenuItem,
      firstLevelItemIndex: number
    ) {
      const eleID = convertStringIntoCamelCase(firstLevelMenuItem?.value)
        return (
          firstLevelMenuItem?.value && <li key={eleID}>
          {firstLevelMenuItem?.fileType == 'download' ? (
            <a
              id={`${eleID}`}
              className={styles.nested}
              ref={mainMenuRef.current[firstLevelItemIndex]}
              key={eleID}
              href={'#toggleLevel1Callback'}
              data-testid={`${eleID}`}
              onClick={async () => {
                if (firstLevelMenuItem.documentPath) {
                  await downloadDocument(
                    firstLevelMenuItem.documentPath,
                    firstLevelMenuItem.value,
                    cookies.locale
                  )
                }
              }}
            >
              {firstLevelMenuItem.value}
            </a>
          ) : (
            <Link href={`${firstLevelMenuItem.url}` || '#'}>
              <a
                id={`${eleID}`}
                className={styles.nested}
                ref={mainMenuRef.current[firstLevelItemIndex]}
                key={eleID}
                href={`${firstLevelMenuItem?.url}` || '#'}
                data-testid={`${eleID}`}
                onClick={(e: any) => {
                  toggleLevel1Callback(
                    e,
                    firstLevelMenuItem?.id,
                    mainMenuRef.current[firstLevelItemIndex]
                  )
                }}
              >
                {firstLevelMenuItem.value}
              </a>
            </Link>
          )}
          {firstLevelMenuItem?.hasSubMenus && (
            <ul className={`${styles.subMenu} ${styles.level2}`}>
              {firstLevelMenuItem?.hasSubMenus &&
                firstLevelMenuItem?.menuItems?.items?.map(function (
                  secondLevelMenuItem: PageHeaderMenuItem,
                  secondLevelItemIndex: any
                ) {
                  return (
                    <li key={secondLevelMenuItem.id}>
                      <Link href={`${secondLevelMenuItem.url}` || '#'}>
                        <a className={styles.nested} href={`${secondLevelMenuItem.url}` || '#'} data-testid={`secondLevel-${secondLevelMenuItem.id}`}>
                          {secondLevelMenuItem.value}
                        </a>
                      </Link>
                      {secondLevelMenuItem?.hasSubMenus && (
                        <ul className={`${styles.subMenu} ${styles.level3} level3`}>
                          {secondLevelMenuItem?.hasSubMenus &&
                            secondLevelMenuItem?.menuItems?.items?.map(function (
                              thirdLevelMenuItem: PageHeaderMenuItem,
                              thirdLevelItemIndex: any
                            ) {
                              const superChild = thirdLevelMenuItem?.url?.split('/');
                              const superChildPathDepth = superChild?.length ?? 0;
                              const superChildVersion = superChild ? superChild[superChild.length - 1] : '';
                              const superChildProductSlug = superChild ? superChild[superChild.length - 2] : '';
                              let superChildProductLink = thirdLevelMenuItem?.url
                              if(!isElavon) {
                                if (thirdLevelMenuItem.value && superChildPathDepth - 1 > 2) {
                                  superChildProductLink = fetchAccessibleProductLink(superChildVersion, superChildProductSlug, allProductVersionsInfo, countryCode);
                                }
                              }
                              let isProductSuiteProductAvailable: any = 0
                              let isProductSuiteProductClassName = ''
                                
                              thirdLevelMenuItem?.menuItems?.items?.map(
                                function (
                                  fourthLevelMenuItem: PageHeaderMenuItem
                                ) {
                                  const subChild = fourthLevelMenuItem?.url?.split('/');
                                  const subChildPathDepth = subChild?.length || 0;
                                  const subChildVersion = subChild ? subChild[subChild.length - 1] : '';
                                  const subChildProductSlug = subChild ? subChild[subChild.length - 2] : '';
                                  let subChildProductLink = fourthLevelMenuItem?.url;
                                  if(!isElavon) {
                                    if (fourthLevelMenuItem?.value && subChildPathDepth == 4) {
                                      subChildProductLink = fetchAccessibleProductLink(subChildVersion, subChildProductSlug, allProductVersionsInfo, countryCode);
                                      if (subChildProductLink) {
                                        isProductSuiteProductAvailable = parseInt(isProductSuiteProductAvailable) + 1
                                      }
                                    }
                                  }
                              })
                              isProductSuiteProductClassName = parseInt(isProductSuiteProductAvailable) == 0 ? 'emptyProductsForProductSuite' : ''
                                
                              if(superChildProductLink) {
                              return (
                                <li key={thirdLevelMenuItem.id}>
                                  <Link
                                    href={`${superChildProductLink}` || '#'}
                                  >
                                    <a className={styles.nested} href={`${superChildProductLink}` || '#'} data-testid={`thirdLevel-${thirdLevelMenuItem.id}`}>
                                      {thirdLevelMenuItem.value}
                                    </a>
                                  </Link>
                                  {/* Product Document Page */}
                                  {thirdLevelMenuItem?.childPages && (
                                    <ul
                                      className={`${styles.childPages} ${styles.level3} ${superChildProductSlug} ${superChildProductSlug == 'product-suites' ? isProductSuiteProductClassName : ''}`}
                                    >
                                      {superChildProductLink != '/products' &&
                                        thirdLevelMenuItem?.childPages != '' && (
                                          <li
                                            className={styles.greatGrandSubMenu}
                                            key={`productOverview-${thirdLevelItemIndex}`}
                                          >
                                            <Link
                                              href={
                                                `${superChildProductLink}` ||
                                                '#'
                                              }
                                            >
                                              <a className={styles.nested} href={
                                                `${superChildProductLink}` ||
                                                '#'
                                              }>
                                                Product Overview
                                              </a>
                                            </Link>
                                          </li>
                                        )}
                                      {thirdLevelMenuItem?.childPages?.map(
                                          function (
                                            productDocumentPage: PageHeaderMenuItem
                                          ) {
                                            const eleID = convertStringIntoCamelCase(productDocumentPage?.value)
                                            return (
                                              <li
                                                className={
                                                  styles.greatGrandSubMenu
                                                }
                                                key={eleID}
                                              >
                                                <Link
                                                  href={
                                                    `${productDocumentPage.url}` ||
                                                    '#'
                                                  }
                                                >
                                                  <a className={styles.nested} href={
                                                    `${productDocumentPage.url}` ||
                                                    '#'
                                                  }>
                                                    {productDocumentPage.value}
                                                  </a>
                                                </Link>
                                              </li>
                                            )
                                          }
                                        )}
                                    </ul>
                                  )}
                                  {/* Product Sub Menus */}
                                  {thirdLevelMenuItem?.hasSubMenus && (
                                    <ul className={`${styles.subMenu}  ${superChildProductSlug}`}>
                                      {thirdLevelMenuItem?.hasSubMenus &&
                                        thirdLevelMenuItem?.menuItems?.items?.map(
                                          function (
                                            fourthLevelMenuItem: PageHeaderMenuItem,
                                            fourthLevelItemIndex: number
                                          ) {
                                            const subChild = fourthLevelMenuItem?.url?.split('/');
                                            const subChildPathDepth = subChild?.length ?? 0;
                                            const subChildVersion = subChild ? subChild[subChild.length - 1] : '';
                                            const subChildProductSlug = subChild ? subChild[subChild.length - 2] : '';
                                            let subChildProductLink = fourthLevelMenuItem?.url;
                                            if(!isElavon) {
                                              if (fourthLevelMenuItem?.value && subChildPathDepth - 1 > 2) {
                                                subChildProductLink = fetchAccessibleProductLink(subChildVersion, subChildProductSlug, allProductVersionsInfo, countryCode);
                                              }
                                            }
                                            if(subChildProductLink) {
                                            return (
                                              <li
                                                className={
                                                  styles.greatGrandSubMenu
                                                }
                                                key={fourthLevelItemIndex}
                                              >
                                                <Link
                                                  href={
                                                    `${subChildProductLink}` ||
                                                    '#'
                                                  }
                                                >
                                                  <a className={styles.nested} href={
                                                    `${subChildProductLink}` ||
                                                    '#'
                                                  }>
                                                    {fourthLevelMenuItem.value}
                                                  </a>
                                                </Link>
                                                {/* Suite Product Document Page */}
                                                {fourthLevelMenuItem?.childPages && (
                                                  <ul
                                                    className={`${styles.childPages} ${styles.level4} ${styles.noArrow}`}
                                                  >
                                                    <li
                                                      className={
                                                        styles.greatGrandSubMenu
                                                      }
                                                      key={`productOverview-${fourthLevelItemIndex}`}
                                                    >
                                                      <Link
                                                        href={
                                                          `${subChildProductLink}` ||
                                                          '#'
                                                        }
                                                      >
                                                        <a
                                                          className={
                                                            styles.nested
                                                          }
                                                          href={
                                                            `${subChildProductLink}` ||
                                                            '#'
                                                          }
                                                        >
                                                          Product Overview
                                                        </a>
                                                      </Link>
                                                    </li>
                                                    {fourthLevelMenuItem?.childPages?.map(
                                                        function (
                                                          productDocumentPage: PageHeaderMenuItem,
                                                          productDocumentPageIndex: number
                                                        ) {
                                                          return (
                                                            <li
                                                              className={
                                                                styles.greatGrandSubMenu
                                                              }
                                                              key={
                                                                productDocumentPageIndex
                                                              }
                                                            >
                                                              <Link
                                                                href={
                                                                  `${productDocumentPage.url}` ||
                                                                  '#'
                                                                }
                                                              >
                                                                <a
                                                                  className={
                                                                    styles.nested
                                                                  }
                                                                  href={
                                                                    `${productDocumentPage.url}` ||
                                                                    '#'
                                                                  }

                                                                >
                                                                  {
                                                                    productDocumentPage.value
                                                                  }
                                                                </a>
                                                              </Link>
                                                            </li>
                                                          )
                                                        }
                                                      )}
                                                  </ul>
                                                )}
                                              </li>
                                            )
                                            }
                                          }
                                        )}
                                    </ul>
                                  )}
                                </li>
                              )
                              }
                            })}
                        </ul>
                      )}
                    </li>
                  )
                })}
            </ul>
          )}
        </li>
      )
    })
  }
  // This will be removed once AEM integration is in place
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const handleMenuOpenCallback = () => {
    setIsMenuOpen(true)
  }

  const handleMenuCloseCallback = () => {
    setIsMenuOpen(false)
  }

  return (
    <>
      {/* render MFA Challenge widget modal */}
      {challengeModalIsOpen && (
        <ChallengeWidgetModal
          callBackUrl={callBackUrl}
          challengeModalIsOpen={challengeModalIsOpen}
          setChallengeModalIsOpen={setChallengeModalIsOpen}
          authWidgetConfigData={authWidgetConfigData}
          activityName={activityName}
        ></ChallengeWidgetModal>
      )}
      <div id="showOverlay" className={styles.showOverlay}></div>
      <USBColumn
        layoutOpts={mobileHeaderNavColumn}
        addClasses={styles.fullWidth}
      >
        <div className={styles.pageHeader}>
          <nav>
            <div className={styles.headerBar}>
              <button
                id="sm_menu_ham"
                className={styles.hamburgerIcon}
                data-testid="sm_menu_ham"
                tabIndex={0}
                onKeyUp={(e: any) => hamburgerKeyup(e)}
                onClick={toggleMenuCallback}
                aria-label="Open left side menu"
              >
                <svg
                  id="svg-692205d2-4c90-4f66-afd9-87504749e1e0"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  width="30"
                  height="30"
                  className="single"
                  focusable="false"
                >
                  <path
                    id="pathSVG-692205d2-4c90-4f66-afd9-87504749e1e0"
                    d="M19 4.75H1v-1.5h18zm0 10.5H1v1.5h18zm0-6H1v1.5h18z"
                  />
                </svg>
              </button>
              <button
                id="hamburgerCloseIcon"
                className={styles.hamburgerCloseIcon}
                data-testid="hamburgerCloseIcon"
                tabIndex={0}
                onKeyUp={(e: any) => hamburgerKeyup(e)}
                onClick={toggleMenuCallback}
                aria-label="Close left side menu"
              >
                {!isElavon ? (<svg
                  width='30'
                  height='30'
                  viewBox='0 0 25 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M19.8873 21.7712L-5.49275e-05 1.88384L1.65723 0.226562L21.5446 20.1139L19.8873 21.7712Z'
                    fill='white'
                  />
                  <path
                    d='M1.65711 21.7712L21.5445 1.88384L19.8872 0.226562L-0.000170849 20.1139L1.65711 21.7712Z'
                    fill='white'
                  />
                </svg>) : (<USBIconClose
                  colorVariant='grey80'
                  size={24}
                  addClasses={styles.profileIcon} />)}
              </button>
              <div className={styles.logoAndTaglineContainer}>
                <USBLink href={'/'} addClasses={styles.profileLink}>
                  <img
                    src={imageURL}
                    alt={process.env.SITE_BRAND + ' logo'}
                  />
                </USBLink>
              </div>
              <div className={styles.navButton}>
                {status !== 'loading' && !session && !isLoginSignUpDisabled && (
                  <USBLink
                    href="/login"
                    addClasses={styles.loginLink}
                    variant="loud"
                    size="default"
                    handleClick={onLoginClick}
                    dataTestId="login-link"
                  >
                    Login
                  </USBLink>
                )}
              </div>
            </div>
            <div className={styles.menuWrapper} ref={menuWrapperRef}>
              <div className={styles.listWrapper} ref={menuListWrapperRef}>
                {!isSearchDisabled && (
                  <InputSearch
                    inputID="edp_search_input_mobile"
                    inputName="query_string_mobile"
                    handleSearchKeyDown={handleSearchKeyDown}
                  />
                )}
                <ul id="menu-level1" className={`${styles.menu} ${styles.level1}`}>
                  
                    {renderMobileMenuItems}
                    {status !== 'loading' &&
                      session &&
                      inSessionMobileMenu.map((item, i) => (
                        <li key={item.id} className={styles.inSessionMobileMenu}>
                          {item.value == 'Credentials' ? (
                            sandboxProductsExists && (
                              <Link href={`${item.url}` || '#'}>
                                <a
                                  id={`${item.id}`}
                                  className={styles.nested}
                                  key={item.id}
                                  href={`${item.url}` || '#'}
                                >
                                  {item.value}
                                </a>
                              </Link>
                            )
                          ) : (
                            <Link href={`${item.url}` || '#'}>
                              <a
                                id={`${item.id}`}
                                className={styles.nested}
                                key={item.id}
                                href={`${item.url}` || '#'}
                              >
                                {item.value}
                              </a>
                            </Link>
                          )}
                        </li>
                      ))}
                    {status !== 'loading' && session && (
                      <li className={styles.inSessionMobileMenu}>
                        <USBButton
                          ctaStyle="standard"
                          emphasis="minimal" 
                          size="medium"
                          handleClick={onLogoutClick}
                          addClasses={styles.logoutBtn}
                          dataTestId="onLogoutClick"
                        >
                          Log Out
                        </USBButton>
                      </li>
                    )}
                    {process.env.FEATURE_GEOLOCATION && (
                      <li className={styles.inSessionMobileMenu}>
                        <RegionPicker
                          options={regionOptions}
                          menuIsOpen={isMenuOpen}
                          handleMenuOpenCallback={handleMenuOpenCallback}
                          handleMenuCloseCallback={handleMenuCloseCallback}
                          classNamePrefix={'mobileFilter'}
                        />
                      </li>
                    )}
                </ul>
              </div>
              <div
                className={styles.listWrapper}
                ref={levelOneMenuListWrapperRef}
                data-testid="toggleGrandSubMenu"
              >
                <button
                  id="levelOne"
                  className={`${styles.backOneLevel} ${styles.levelOne}`}
                  ref={backOneLevelRef}
                  data-testid="togglebackOneLevelCallback"
                  onClick={togglebackOneLevelCallback}
                  tabIndex={0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                  </svg>
                  <span id="level1-backMenu">Main Menu</span>
                </button>
                <div
                  id="activeLevelOneMenu"
                  className={styles.activeLevelOneMenu}
                ></div>
                <div
                  className={`${styles.subMenuWrapper} subMenuWrapper`}
                ></div>
              </div>
              <div
                className={styles.listWrapper}
                ref={levelTwoMenuListWrapperRef}
              >
                <button
                  id="levelTwo"
                  className={`${styles.backOneLevel} ${styles.levelTwo}`}
                  ref={backOneLevelRef}
                  data-testid="togglebackSecondLevelCallback"
                  onClick={togglebackSecondLevelCallback}
                  tabIndex={0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                  </svg>
                  <span id="level2-backMenu">Back</span>
                </button>
                <div
                  id="activeLevelTwoMenu"
                  className={styles.activeLevelTwoMenu}
                ></div>
                <div
                  className={`${styles.subMenuWrapper} subMenuWrapper`}
                ></div>
              </div>
              <div
                className={styles.listWrapper}
                ref={levelThreeMenuListWrapperRef}
              >
                <button
                  id="levelThree"
                  className={`${styles.backOneLevel} ${styles.levelThree}`}
                  ref={backOneLevelRef}
                  data-testid="togglebackThirdLevelCallback"
                  onClick={togglebackThirdLevelCallback}
                  tabIndex={0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                  </svg>
                  <span id="level3-backMenu">Back</span>
                </button>
                <div
                  id="activeLevelThreeMenu"
                  className={styles.activeLevelThreeMenu}
                ></div>
                <div
                  className={`${styles.subMenuWrapper} subMenuWrapper`}
                ></div>
              </div>
            </div>
          </nav>
        </div>
      </USBColumn>
    </>
  )
}
